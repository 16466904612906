<template>
  <div>
    <GmapMap
      :center="center"
      :zoom="zoom"
      style="width: 100%; height: 400px"
      ref="mapRef"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :title="m.title"
        @click="center = m.position"
      />
    </GmapMap>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
export default {
  name: "Map",
  data() {
    return {
      center: { lat: 45.508, lng: -73.587 },
      currentPlace: null,
      places: [],
      markers: [],
      zoom: null,
    };
  },
  props: {
    markersList: { type: Array },
    mapPanCenter: { type: Object },
  },
  mounted() {
    this.geolocate();
  },

  computed: {
    google: gmapApi,
  },

  watch: {
    markersList(newVal) {
      if (newVal.length === 1) {return}
      this.markers = [...newVal];
      let self = this;
      this.$refs.mapRef.$mapPromise.then((map) => {
        //get ref to map and set the zoom according to the loaded markers
        //https://github.com/xkjyeah/vue-google-maps#readme
        let bounds = new self.google.maps.LatLngBounds();

        self.markers.forEach((marker) => {
           bounds.extend(
            new self.google.maps.LatLng(
              marker.position.lat,
              marker.position.lng
            )
          );
        });
        map.fitBounds(bounds);
      });
    },
    mapPanCenter(coordinates) {
      if (coordinates) {
        this.center = coordinates;
        this.zoom = 14;
      }
    },
  },

  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },

    geolocate: function () {
      this.$getLocation({}).then((coordinates) => {
        console.log(coordinates);
        this.center = {
          lat: coordinates.lat,
          lng: coordinates.lng,
        };
      });
    },
  },
};
</script>