<template>
  <v-container>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="4" style="padding: 0 12px 12px 0">
        <v-text-field
          placeholder="search sites"
          outlined
          hide-details
          v-model="siteSearch"
          clearable
          ><template v-slot:prepend-inner>
            <v-icon large>mdi-magnify</v-icon>
          </template></v-text-field
        >
      </v-col></v-row
    >
    <site-map
      :markersList="siteCoordinates"
      :mapPanCenter="mapPanCenter"
    ></site-map>
    <PortfolioList
      :portfolioDetails="computedPortfolios"
      :finishedLoading="finishedLoading"
      @pangooglemapto="panTo"
  /></v-container>
</template>
<style>
</style>

<script>
import { mapGetters } from "vuex";
import store from "../_store";
import api from "../_api";
import PortfolioList from "./PortfolioList";
import Map from "./Map.vue";
import { createSiteStartStopTimeResourceModel } from "@/modules/site/_resourceModels/createSiteStartStopTimeResourceModel";
import { createStartStopTimeResourceModel } from "@/modules/site/_resourceModels/createStartStopTimeResourceModel";

const STORE_KEY = "$_portfolios";

export default {
  name: "PortfolioModule",
  components: { PortfolioList, "site-map": Map },

  computed: {
    ...mapGetters({
      userId: "session/jwtSubject",
    }),
    computedPortfolios: function () {
      let self = this;
      if (this.siteSearch === null || this.siteSearch === "") {
        return this.portfolios;
      }
      let result = this.portfolios.filter((p) => {
        if (
          p.name.toLowerCase().includes(self.siteSearch.toLowerCase()) ||
          p.address.toLowerCase().includes(self.siteSearch.toLowerCase())
        ) {
          return p;
        }
      });

      return result;
    },
  },

  watch: {
  },

  data() {
    return {
      portfolios: null,
      siteSearch: null,

      dayOfWeek: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      finishedLoading: false,
      siteCoordinates: [],
      mapPanCenter: null,
    };
  },

  async created() {
    this.portfolios = await api.getPortfolioSites();
    console.log(this.portfolios);
    this.portfolios.forEach((p) => {
      p.mappedStartStopTimes = this.mapSiteStartStopTime(
        p.startStopTimeResourceModels
      );
    });
    this.siteCoordinates = this.mapSiteLocations(this.portfolios);
    this.finishedLoading = true;
  },

  beforeCreate() {
    if (!this.$store._modules.root._children[STORE_KEY])
      this.$store.registerModule(STORE_KEY, store);
  },

  methods: {
    panTo(siteCoordinates) {
      console.log("pan requested");
      console.log(siteCoordinates);
      this.mapPanCenter = siteCoordinates;
    },

    mapSiteStartStopTime(sssTimes) {
      let mappedTimes = [];

      for (let i = 0; i < this.dayOfWeek.length; i++) {
        let existingSssTime = sssTimes.find((t) => t.dayOfWeekId === i);
        if (existingSssTime) {
          existingSssTime.isOpen = true;
          mappedTimes.push(existingSssTime);
        } else {
          //let newSssTime = this.newStartStopTime(i, this.dayOfWeek[i], false);

          let newSssTime = {
            dayOfWeekId: i,
            dayOfWeekName: this.dayOfWeek[i],
            isOpen: false,
            siteStartTime: "07:00:00",
            siteEndTime: "19:00:00",
          };

          mappedTimes.push(newSssTime);
        }
      }
      return mappedTimes;
    },

    newStartStopTime(
      index,
      dayOfWeekName,
      isOpen,
      startStopTime = new createStartStopTimeResourceModel(
        "07:00:00",
        "19:00:00"
      )
    ) {
      let newSssTime = new createSiteStartStopTimeResourceModel(
        this.$route.params.id,
        index,
        dayOfWeekName,
        isOpen
      );
      startStopTime.id = 0; //TODO: make an update resource model
      newSssTime.startStopTime = startStopTime;
      newSssTime.id = 0;
      return newSssTime;
    },

    mapSiteLocations(portfolio) {
      const locationList = portfolio.map((site) => {
        return {
          position: { lat: site.latitude, lng: site.longitude },
          title: site.name,
        };
      });
      console.log(locationList);
      return locationList;
    },
  },
};
</script>