<template>
  <v-row justify="start" align="start">
    <v-col
      v-for="site in sites"
      :key="site.id"
      :sm="12"
      :md="6"
      :lg="4"
      :xl="3"
    >
      <v-card class="flip-card" @click="panTo(site)">
        <v-card-title
          class="title blue-grey lighten-4 pl-4"
          style="
            word-break: break-word;
            height: 76px !important;
            line-height: 1.5rem !important;
          "
        >
          {{ site.name }}
        </v-card-title>
        <v-divider />
        <v-card-text
          class="flip-card-inner"
          ref="flip-card-ref"
          :class="site.isFlipped ? 'flipper' : ''"
        >
          <v-card-text
            class="flip-card-front"
            :class="site.isFlipped ? 'front-flipped' : ''"
          >
            <v-row align="start">
              <v-col>
                <v-img
                  v-if="site.siteImageURL !== null"
                  :src="site.siteImageURL"
                  class="ml-3 mr-3"
                  height="200px"
                ></v-img>
                <!-- conditional data url for served image blob -->
                <v-img
                  v-else
                  src="https://energy360sr.blob.core.windows.net/energy-360-site-images/large-building.jpg"
                  class="ml-3 mr-3"
                  height="200px"
                ></v-img>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="3" align="center"
                ><v-icon class="mt-n2">{{
                  getWeatherIcon(
                    site.siteWeatherAndEnergyDataResourceModel.weatherIcon
                  )
                }}</v-icon>

                <v-card-text class="mt-n1 pt-0"
                  >{{
                    parseFloat(
                      site.siteWeatherAndEnergyDataResourceModel.temperature
                    ).toFixed(0)
                  }}°&nbsp;F</v-card-text
                >
              </v-col>
              <v-col cols="9"
                ><v-card-text class="mt-n4" style="line-height: 1.2rem">
                  {{ site.streetNumber }} {{ site.street }}
                  <br />
                  {{ site.city }}, {{ site.state }}
                  {{ site.postalCode }}
                </v-card-text>
              </v-col>
            </v-row>

            <v-row class="mt-n5"
              ><v-col cols="4" align="center"
                ><v-img
                  max-height="64"
                  max-width="64"
                  src="../../../static/images/PeakV2.png"
                ></v-img>
              </v-col>
              <v-col cols="8" align="center">
                <v-row
                  ><v-col
                    ><div class="mt-n2">Daily Peak Demand (kW)</div>
                    <br />
                    <div class="display-1" style="margin-top: -24px !important">
                      {{
                        site.siteWeatherAndEnergyDataResourceModel.peakDemand ||
                        "N/A"
                      }}
                    </div>
                  </v-col></v-row
                >
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text
            class="flip-card-back"
            :class="!site.isFlipped ? 'back-flipped' : ''"
          >
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Hours of Operation
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <section class="portfolio-hours-of-op">
                      <v-list-item
                        v-for="time in site.mappedStartStopTimes"
                        :key="time.id"
                      >
                        <v-list-item-title>{{
                          time.dayOfWeekName
                        }}</v-list-item-title>
                        <v-list-item-title
                          class="align-end"
                          v-if="time.isOpen === true"
                          >{{ time.siteStartTime }} -
                          {{ time.siteEndTime }}</v-list-item-title
                        >
                        <v-list-item-title class="align-end" v-else
                          >Closed</v-list-item-title
                        >
                      </v-list-item>
                    </section></v-list
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Building Details
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>Site Type</v-list-item-content>
                      <v-list-item-content class="align-end">{{
                        site.siteTypeName
                      }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Occupancy</v-list-item-content>
                      <v-list-item-content class="align-end">{{
                        site.occupants
                      }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Year Build</v-list-item-content>
                      <v-list-item-content class="align-end">{{
                        site.year
                      }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Square Footage</v-list-item-content>
                      <v-list-item-content class="align-end">{{
                        site.squareFootage.toLocaleString()
                      }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Floors</v-list-item-content>
                      <v-list-item-content class="align-end">{{
                        site.floors
                      }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Site Location
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>Address</v-list-item-content>
                      <v-list-item-content class="align-end">{{
                        site.address
                      }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Latitude</v-list-item-content>
                      <v-list-item-content class="align-end">{{
                        site.latitude
                      }}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Longitude</v-list-item-content>
                      <v-list-item-content class="align-end">{{
                        site.longitude
                      }}</v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>Weather Station</v-list-item-content>
                      <v-list-item-content class="align-end">{{
                        site.weatherStationName
                      }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  Site Utility Companies
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense>
                    <v-list-item
                      v-for="(name, i) in site.utilityCompanyNames"
                      :key="i"
                    >
                      <v-list-item-content>{{ name }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-tooltip bottom v-if="site.hasSubMeter">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                @click="goToSubmeterReport(site.siteId, site.powerBiReports.SubMeter)"
                v-on="on"
              >
                <v-icon>insert_chart</v-icon>
              </v-btn>
            </template>
            <span>View Submetering Report for this site</span>
          </v-tooltip>
          <v-tooltip bottom v-if="site.hasUtility">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                @click="goToUtilityReport(site.siteId, site.powerBiReports.Utility)"
                v-on="on"
              >
                <v-icon>electrical_services</v-icon>
              </v-btn>
            </template>
            <span>View Utility Data Report for this site</span>
          </v-tooltip>
          <v-tooltip bottom v-if="site.hasThermostat">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :to="`/controls/thermostats/site/${site.siteId}`"
                v-on="on"
              >
                <v-icon>device_thermostat</v-icon>
              </v-btn>
            </template>
            <span>View Thermostats for site</span>
          </v-tooltip>
          <v-tooltip bottom v-if="site.hasPowerControl">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :to="`/controls/power-controls/site/${site.siteId}`"
                v-on="on"
              >
                <v-icon>power</v-icon>
              </v-btn>
            </template>
            <span>View Power Controls for this site</span>
          </v-tooltip>
          <v-tooltip bottom v-if="site.hasSensor">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                @click="goToSensorReport(site.siteId, site.powerBiReports.Sensor)"
                v-on="on"
              >
                <v-icon>speed</v-icon>
              </v-btn>
            </template>
            <span>View Sensor report for this site</span>
          </v-tooltip>
          <v-tooltip bottom v-if="site.hasIAQ">
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                @click="goToIAQReport(site.siteId, site.powerBiReports.IAQ)"
                v-on="on"
              >
                <v-icon>co2</v-icon>
              </v-btn>
            </template>
            <span>View IAQ report for this site</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon @click="flipCard(site)" class="flip-btn" v-on="on"
                >autorenew</v-icon
              >
            </template>
            <span>View Site Details</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped>
.flip-card {
  border: 1px solid #f1f1f1;
  min-height: 500px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  min-height: 400px;
  padding: 0 !important;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flipper {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  padding: 0 !important;
  /* backface-visibility: hidden; */
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

.back-flipped,
.front-flipped {
  display: none;
}

.flip-btn {
  cursor: pointer;
}
</style>

<script>
import { weatherIcon } from "@/plugins/weather-icon-mapper";
import "@/assets/styles/weather-icons/css/weather-icons.css";

export default {
  name: "PortfolioList",

  components: {},

  props: {
    portfolioDetails: { type: Array },
    finishedLoading: { type: Boolean },
  },

  data() {
    return {
      sites: [],
    };
  },

  watch: {
    portfolioDetails: function (newVal) {
      this.sites = [...newVal];
      this.sites.forEach((s) => {
        s.isFlipped = false;
      });
    },
  },

  methods: {
    panTo(selectedSite) {
      this.$emit("pangooglemapto", {
        lat: selectedSite.latitude,
        lng: selectedSite.longitude,
      });
    },
    getWeatherIcon(name) {
      return weatherIcon(name);
    },
    flipCard(site) {
      site.isFlipped = !site.isFlipped;
    },

    goToSubmeterReport(siteId, reportId) {
      this.$router.push({
        name: "PortfolioReportRoute",
        params: {
          id: reportId,
          siteId: siteId,
          reportFilters: {
          },
        },
      });
    },

    goToUtilityReport(siteId, reportId) {
      this.$router.push({
        name: "PortfolioReportRoute",
        params: {
          id: reportId,
          siteId: siteId,
          reportFilters: {
           },
        },
      });
    },

    goToIAQReport(siteId, reportId) {
      this.$router.push({
        name: "PortfolioReportRoute",
        params: {
          id: reportId,
          siteId: siteId,
          reportFilters: {
 
          },
        },
      });
    },

    goToSensorReport(siteId, reportId) {
      this.$router.push({
        name: "PortfolioReportRoute",
        params: {
          id: reportId,
          siteId: siteId,
          reportFilters: {
 
          },
        },
      });
    },
  },
};
</script>