import Vue from 'vue'
const getPortfolios = async () => {
  try {
    let result = await window.axios.get('/v1/sites/portfolio');
    if (result.data.data != undefined) {
      result.data.data.forEach((key) => {
        Vue.set(key, 'isFlipped', false)
      });
    }
    return result.data.data;
    //return result != undefined ? result.data.data : []; 
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const getPortfolioSites = async () => {
  try {
    let result = await window.axios.get('/v1/viewsupport/portfolio/sitedatadetail');
    if (result.data.data != undefined) {
      result.data.data.forEach((key) => {
        Vue.set(key, 'isFlipped', false)
      });
    }
    return result.data.data;
    //return result != undefined ? result.data.data : []; 
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

const getPortfolioById = async (portfolioId) => {
  var site = await window.axios.get(`/v1/sites/${portfolioId}`);
  return site.data.data;
};

const uploadOccupancyCsv = async (formData, siteId) => {
  let result = null;
  try {
    result = await window.axios3.post('/v1/sensor/sites/' + siteId + '/occupancy/monthly', formData);
    return result.data.data;
  } catch (err) {
    console.error(err);
    return Promise.reject(err);
  }
};


export default {
  uploadOccupancyCsv,
  getPortfolioById,
  getPortfolios,
  getPortfolioSites
 
};
