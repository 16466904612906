import api from '../_api';

const getPortfolios = async (context) => {
    const portfolios = await api.getPortfolios();
    console.log(portfolios);
    context.commit('PORTFOLIOS_UPDATED', portfolios);
};

const getPortfolioById = async (context, portfolioId) => {
    const portfolio = await api.getPortfolioById(portfolioId);
    context.commit('application/setActivePortfolio', portfolio, { root: true });
}

export default { 
    getPortfolios,
    getPortfolioById
};